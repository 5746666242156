import component from "@wagich/svelte-tag";

import { trackEvent, trackPageView } from "./analytics";
trackPageView();

import "./gallery";
import "./favorite-list";
import "./inquiry";
import "./navigation";
import "./team";

if (document.querySelector("bluc-map") != null) {
	import("@/components/bluc-map.svelte").then(m => {
		new component({
			component: m.default,
			tagname: "bluc-map"
		});
	});
}

import { Fancybox, Panzoom, Carousel } from "@fancyapps/ui";
Carousel.defaults.Navigation = {
	nextTpl: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7.21 23.75 12.47-11a1.06 1.06 0 0 0 0-1.54L7.21.25A1 1 0 0 0 6.48 0a1 1 0 0 0-.69.36L4.24 2.21A1 1 0 0 0 4 3a1 1 0 0 0 .35.69l9.45 8.17a.27.27 0 0 1 0 .38l-9.45 8.12a1 1 0 0 0-.35.69 1 1 0 0 0 .23.74l1.56 1.85a1 1 0 0 0 .69.36 1 1 0 0 0 .73-.25Z" fill="currentColor"></path></svg>',
	prevTpl: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m16.79.25-12.47 11a1.06 1.06 0 0 0 0 1.54l12.47 11a1 1 0 0 0 .73.25 1 1 0 0 0 .69-.36l1.55-1.85a1 1 0 0 0 .24-.74 1 1 0 0 0-.35-.69l-9.45-8.21a.27.27 0 0 1 0-.38l9.45-8.17A1 1 0 0 0 20 3a1 1 0 0 0-.23-.74L18.21.36a1 1 0 0 0-.69-.36 1 1 0 0 0-.73.25Z" fill="currentColor"></path></svg>'
};
Fancybox.defaults = {
	tpl: {
		closeButton: `<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M14.3 12.18a.24.24 0 0 1 0-.35l9.26-9.27a1.49 1.49 0 0 0 0-2.12 1.51 1.51 0 0 0-2.12 0L12.18 9.7a.25.25 0 0 1-.36 0L2.56.44a1.51 1.51 0 0 0-2.12 0 1.49 1.49 0 0 0 0 2.12l9.26 9.27a.24.24 0 0 1 0 .35L.44 21.44a1.49 1.49 0 0 0 0 2.12 1.51 1.51 0 0 0 2.12 0l9.26-9.26a.25.25 0 0 1 .36 0l9.26 9.26a1.51 1.51 0 0 0 2.12 0 1.49 1.49 0 0 0 0-2.12Z"></path></svg></button>`
	},
	Thumbs: false,
	Toolbar: {
		display: {
			right: ["fullscreen", "thumbs", "close"]
		},
		items: {
			infobar: {
				tpl: '<div class="fancybox__infobar" tabindex="-1"><span data-fancybox-current-index></span>/<span data-fancybox-count></span></div>'
			},
			download: {
				tpl: '<a class="f-button" title="{{DOWNLOAD}}" data-fancybox-download href="javasript:;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M22.67 16.94a1.22 1.22 0 0 0-1.22 1.21V20a1.57 1.57 0 0 1-1.57 1.57H4.12A1.57 1.57 0 0 1 2.55 20v-1.85a1.22 1.22 0 0 0-2.43 0V20a4 4 0 0 0 4 4h15.76a4 4 0 0 0 4-4v-1.85a1.21 1.21 0 0 0-1.21-1.21Z" fill="currentColor"></path><path d="M12 0a1.94 1.94 0 0 0-1.94 1.94V11a.25.25 0 0 1-.25.25H7.15a1 1 0 0 0-.73 1.6l4.85 5.58a1 1 0 0 0 1.46 0l4.85-5.58a1 1 0 0 0-.73-1.6h-2.66a.25.25 0 0 1-.25-.25V1.94A1.94 1.94 0 0 0 12 0Z" fill="currentColor"></path></g></svg></a>'
			},
			prev: {
				tpl: '<button class="f-button" title="{{PREV}}" data-fancybox-prev><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m16.79.25-12.47 11a1.06 1.06 0 0 0 0 1.54l12.47 11a1 1 0 0 0 .73.25 1 1 0 0 0 .69-.36l1.55-1.85a1 1 0 0 0 .24-.74 1 1 0 0 0-.35-.69l-9.45-8.21a.27.27 0 0 1 0-.38l9.45-8.17A1 1 0 0 0 20 3a1 1 0 0 0-.23-.74L18.21.36a1 1 0 0 0-.69-.36 1 1 0 0 0-.73.25Z" fill="currentColor"></path></svg></button>'
			},
			next: {
				tpl: '<button class="f-button" title="{{NEXT}}" data-fancybox-next><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7.21 23.75 12.47-11a1.06 1.06 0 0 0 0-1.54L7.21.25A1 1 0 0 0 6.48 0a1 1 0 0 0-.69.36L4.24 2.21A1 1 0 0 0 4 3a1 1 0 0 0 .35.69l9.45 8.17a.27.27 0 0 1 0 .38l-9.45 8.12a1 1 0 0 0-.35.69 1 1 0 0 0 .23.74l1.56 1.85a1 1 0 0 0 .69.36 1 1 0 0 0 .73-.25Z" fill="currentColor"></path></svg></button>'
			},
			slideshow: {
				tpl: '<button class="f-button" title="{{TOGGLE_SLIDESHOW}}" data-fancybox-toggle-slideshow><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.14 10.61 2.25.16A1.56 1.56 0 0 0 0 1.56v20.88a1.56 1.56 0 0 0 2.25 1.4l20.89-10.45a1.55 1.55 0 0 0 0-2.78Z" fill="currentColor"></path></svg></button>'
			},
			fullscreen: {
				tpl: '<button class="f-button" title="{{TOGGLE_FULLSCREEN}}" data-fancybox-toggle-fullscreen><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.75 2.45a2.21 2.21 0 0 0-2.2-2.2h-5.73a1.25 1.25 0 0 0 0 2.5H21a.25.25 0 0 1 .25.25v3.27a1.25 1.25 0 0 0 2.5 0Z" fill="currentColor"></path><path d="M.25 6.27a1.25 1.25 0 0 0 2.5 0V3A.25.25 0 0 1 3 2.75h5.18a1.25 1.25 0 0 0 0-2.5H2.45a2.21 2.21 0 0 0-2.2 2.2Z" fill="currentColor"></path><path d="M23.75 21.54v-3.81a1.25 1.25 0 0 0-2.5 0V21a.25.25 0 0 1-.25.25h-5.18a1.25 1.25 0 0 0 0 2.5h5.73a2.21 2.21 0 0 0 2.2-2.21Z" fill="currentColor"></path><path d="M.25 21.54a2.21 2.21 0 0 0 2.2 2.21h5.73a1.25 1.25 0 0 0 0-2.5H3a.25.25 0 0 1-.25-.25v-3.27a1.25 1.25 0 0 0-2.5 0Z" fill="currentColor"></path><path d="M7.22 8.14h9.55s1.95 0 1.95 1.95v3.83s0 1.95-1.95 1.95H7.22s-1.95 0-1.95-1.95v-3.83s0-1.95 1.95-1.95" fill="currentColor"></path></svg></button>'
			},
			thumbs: {
				tpl: '<button class="f-button" title="{{TOGGLE_THUMBS}}" data-fancybox-toggle-thumbs><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M1.75.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M1.75 8.75h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M1.75 17.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M10.25.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M10.25 8.75h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M10.25 17.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M18.75.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M18.75 8.75h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path><path d="M18.75 17.25h3.5s1.5 0 1.5 1.5v3.5s0 1.5-1.5 1.5h-3.5s-1.5 0-1.5-1.5v-3.5s0-1.5 1.5-1.5" fill="currentColor"></path></g></svg></button>'
			},
			close: {
				tpl: '<button class="f-button" title="{{CLOSE}}" data-fancybox-close><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M14.3 12.18a.24.24 0 0 1 0-.35l9.26-9.27a1.49 1.49 0 0 0 0-2.12 1.51 1.51 0 0 0-2.12 0L12.18 9.7a.25.25 0 0 1-.36 0L2.56.44a1.51 1.51 0 0 0-2.12 0 1.49 1.49 0 0 0 0 2.12l9.26 9.27a.24.24 0 0 1 0 .35L.44 21.44a1.49 1.49 0 0 0 0 2.12 1.51 1.51 0 0 0 2.12 0l9.26-9.26a.25.25 0 0 1 .36 0l9.26 9.26a1.51 1.51 0 0 0 2.12 0 1.49 1.49 0 0 0 0-2.12Z"></path></svg></button>'
			}
		},
	}
};
Fancybox.bind("[data-fancybox], .is-zoomable", {
	hideScrollbar: false,
	caption: (fancybox, slide, caption) => {
		if (typeof caption === "string") {
			let parts = caption.split("__");
			if (parts.length != 2) {
				return caption;
			}
			if (parts.length === 2) {
				return `<span class="tag is-dark">${parts[1]}</span> ${parts[0]}`;
			}
		}
		return caption ?? false;
	}
});

import tippy from "tippy.js";
tippy("[data-tippy-content]");

const dropdowns = document.querySelectorAll<HTMLElement>(".dropdown:not(.is-hoverable)");
if (dropdowns.length > 0) {
	for (let element of dropdowns) {
		element.addEventListener("click", (event) => {
			event.stopPropagation();
			element.classList.toggle("is-active");
		});
	}
	document.addEventListener("click", (event) => {
		for (let element of dropdowns) {
			element.classList.remove("is-active");
		}
	});
}

import Flickity from "flickity";
import imagesLoaded from "imagesloaded";

for (let element of document.querySelectorAll<HTMLElement>(".asset-list--image")) {
	new Flickity(element, {

	});
}


let isScrolled = false;
window.addEventListener("scroll", e => {
	const className = "is-scrolled";
	if (!isScrolled && window.scrollY > 60) {
		isScrolled = true;
		document.documentElement.classList.add(className);
	}
	if (isScrolled && window.scrollY <= 60) {
		isScrolled = false;
		document.documentElement.classList.remove(className);
	}
});

import "../Styles/index.scss";
