<script lang="ts">
	import { onMount } from "svelte";
	import maxBy from "lodash/maxBy";

	import SvgIcon from "@/components/svg-icon.svelte";
	import { loadFromJsonScriptElement, slugify } from "@/utils";

	export let departments: string[];
	export let departmentsSelector: string | null = null;

	let activeDepartment: string = "";
	$: longestDepartmentCharCount = maxBy(departments, c => c.length)?.length;

	onMount(() => {
		if (departments == null && departmentsSelector != null) {
			departments = loadFromJsonScriptElement<string[]>(departmentsSelector) ?? [];
			activeDepartment = departments[0] ?? "";
		}

		let observer = new IntersectionObserver(
			(entries, _) => {
				let entry = entries.filter((e) => e.isIntersecting)[0];
				if (entry?.isIntersecting) {
					let searchValue = (entry.target as HTMLElement).dataset.department;
					activeDepartment = departments.find((c) => slugify(c) === searchValue) ?? "";
				}
			},
			{
				// single trigger at top edge of target [threshold: 0], firing 
				// when it hits the middle of the viewport [rootMargin: -50%]
				rootMargin: "-50% 0px",
				threshold: 0,
			}
		);
		for (let element of document.querySelectorAll<HTMLElement>(".team__employee")) {
			observer.observe(element);
		}
	});
</script>

<div class="dropdown" style={`--maxchars: ${longestDepartmentCharCount}`}>
	<div class="dropdown-trigger">
		<button class="button" aria-haspopup="true" aria-controls="department-dropdown-menu">
			<span>{activeDepartment}</span>
			<SvgIcon icon="arrow-button-down" customClass="is-small" />
		</button>
	</div>
	<div class="dropdown-menu" id="department-dropdown-menu" role="menu">
		<div class="dropdown-content">
			{#each departments ?? [] as category}
				<a class="dropdown-item" href={`#${slugify(category)}`}>{category}</a>
			{/each}
		</div>
	</div>
</div>
