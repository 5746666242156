<script lang="ts">
	import Packery from "packery";
	import { onMount, tick } from "svelte";
	import sample from "lodash/sample";
	import { derived, writable, Writable } from "svelte/store";
	import { Readable } from "svelte/motion";
	
	import { GalleryImage } from "@/models";
	import FavoriteToggle from "@/favorite-list/favorite-toggle.svelte";
    import { loadFromJsonScriptElement } from "@/utils";

	interface ItemVm extends GalleryImage {
		width: string;
		height: string;
		objectPosition: string;
	}

	export let dataSelector: string;

	let packery: Packery;
	let packeryElement: HTMLElement;
	let sizes = {
		portrait: [
			[4.25, 9],
			[4.25, 11],
		],
		landscape: [
			[7, 8],
			[8, 8],
		],
	};

	let data: Writable<GalleryImage[]> = writable([]);
	let items: Readable<ItemVm[]> = derived(data, ($items) =>
		$items.map((x) => {
			let size = sample(sizes[x.aspectRatio > 1 ? "landscape" : "portrait"])!;
			return { 
				...x, 
				width: `calc(${size[0]} * var(--grid-h))`, 
				height: `calc(${size[1]} * var(--grid-v))`,
				objectPosition: `${50 + x.focalPoint.x * 50}% ${50 + x.focalPoint.y * 50}%`
			};
		})
	);

	onMount(async () => {
		$data = loadFromJsonScriptElement<GalleryImage[]>(dataSelector) ?? [];

		await tick();
		packery = new Packery(packeryElement, {
			originLeft: false,
			initLayout: false,
			itemSelector: ".gallery-stream__item",
			gutter: ".gallery-stream__gap-sizer",
		});
		packery.stamp(document.querySelectorAll(".cta-block"));
		packery.layout();
	});

	function getImageUrl(path: string, width: number, height: number, resizeMode: string): string {
		var url = new URL(path, window.location.href);
		url.searchParams.set("width", width.toFixed(0));
		url.searchParams.set("height", height.toFixed(0));
		url.searchParams.set("rmode", resizeMode);
		return url.href;
	}
</script>

<div bind:this={packeryElement} class="gallery-stream">
	<div class="gallery-stream__grid-sizer" />
	<div class="gallery-stream__gap-sizer" />

	{#each $items as item}
		<div class="gallery-stream__item" style:width={item.width} style:height={item.height}>
			<div class="gallery-item__identifier tag is-dark">{item.identifier}</div>
			<div class="gallery-item__favorite">
				<FavoriteToggle item={item} />
			</div>
			<img class="gallery-stream__image" style:object-position={item.objectPosition} src={getImageUrl(item.imageUrl, 800, 800, "max")} data-fancybox="gallery-stream" data-src="{getImageUrl(item.imageUrl, 1800, 1300, "max")}" data-caption={`${item.description}__${item.identifier}`} alt={item.description} />
		</div>
	{/each}
</div>
