import * as urlSlug from "url-slug";

export enum FormState {
	Loading = "loading",
	Ready = "ready",
	Success = "success",
	Error = "error",
}

export function loadFromJsonScriptElement<T>(selector: string): T | null {
	let maybeScript = document.querySelector(selector);
	if (maybeScript?.tagName !== "SCRIPT") {
		return null;
	}
	let script = maybeScript as HTMLScriptElement;
	if (script.type !== "application/json") {
		return null;
	}
	return JSON.parse(script.textContent ?? "null");
}

export function slugify(value: string) {
	return urlSlug.convert(value, {
		dictionary: {
			"ä": "ae",
			"ö": "oe",
			"ü": "ue",
		}
	});
}
