<script lang="ts">
	import tippy from "svelte-tippy";
    import { onMount } from "svelte";

	import SvgIcon from "../components/svg-icon.svelte";
	import { store } from "./store";
	import { GalleryImage } from "@/models";
    import { loadFromJsonScriptElement } from "@/utils";
	import { bundles, localize } from "@/localization";

	export let item: GalleryImage | null;
	export let itemSelector: string | null = null;

	let isSelected = store.has(item?.id);
	store.subscribe((map) => {
		isSelected = map.has(item?.id);
	});

	let tooltip = isSelected ? "Von der Merkliste entfernen" : "Merken";

	onMount(() => {
		if (item == null && itemSelector != null) {
			item = loadFromJsonScriptElement<GalleryImage>(itemSelector);
		}
	});

	function toggle() {
		if (item == null) {
			return;
		}

		if (store.has(item.id)) {
			store.delete(item.id);
		} else {
			store.add(item);
		}
	}
</script>

<button class="button is-ghost is-rounded favorite-toggle" class:is-dark={!isSelected} class:is-primary={isSelected} on:click={toggle}>
	<SvgIcon icon="love-it" {tooltip} />
</button>
