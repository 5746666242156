import { persist, createLocalStorage } from "@macfja/svelte-persistent-store";
import { Invalidator, Subscriber, Unsubscriber, get, writable } from "svelte/store";
import { Id, GalleryImage } from "../models";

export class FavoriteStore {
	private store = persist(writable(new Map<Id, GalleryImage>()), createLocalStorage(), "favorites-v1");

	has(id: Id): boolean {
		var state = get(this.store);
		return state.has(id);
	}

	count(): number {
		var state = get(this.store);
		return state.size;
	}

	add(item: GalleryImage) {
		var state = get(this.store);
		state.set(item.id, item);
		this.store.set(state);
	}

	delete(id: Id) {
		var state = get(this.store);
		state.delete(id);
		this.store.set(state);
	}

	clear() {
		var state = get(this.store);
		state.clear();
		this.store.set(state);
	}

	getUrl(baseUrl: string): URL {
		var state = get(this.store);
		let url = new URL("", baseUrl);
		url.hash = `#!${[...state.keys()].join(",")}`;
		return url;
	}

	subscribe(run: Subscriber<Map<Id, GalleryImage>>, invalidate?: Invalidator<Map<Id, GalleryImage>>): Unsubscriber {
		return this.store.subscribe(run, invalidate);
	}
}

export const store = new FavoriteStore();
