import component from "@wagich/svelte-tag";
import Flickity from "flickity";
import "flickity-imagesloaded";
import GalleryStream from "./gallery-stream.svelte";
import "./gallery";

new component({
	component: GalleryStream,
	tagname: "gallery-stream",
});

let pageGallerySlider = document.getElementById("pageGallerySlider");
if (pageGallerySlider != null) {
	new Flickity(pageGallerySlider, {
		imagesLoaded: true,
		pageDots: false,
		setGallerySize: false,
		percentPosition: false,
		arrowShape: "m53.24,1.05L1.38,46.8c-1.77,1.67-1.85,4.46-.17,6.23.06.06.11.12.17.17l51.86,45.75c.83.74,1.93,1.11,3.04,1.04,1.12-.09,2.15-.63,2.87-1.5l6.45-7.69c.73-.85,1.09-1.96,1-3.08-.08-1.11-.61-2.15-1.46-2.87L25.83,50.71c-.43-.44-.43-1.14,0-1.58L65.14,15.15c.8-.67,1.32-1.62,1.46-2.66.11-1.11-.24-2.22-.96-3.08l-6.49-7.9c-.72-.87-1.75-1.41-2.87-1.5-1.11-.07-2.2.3-3.04,1.04Z"
	});
}
