<script lang="ts">
	import { onMount } from "svelte";
	import maxBy from "lodash/maxBy";

	import SvgIcon from "@/components/svg-icon.svelte";
	import { loadFromJsonScriptElement, slugify } from "@/utils";

	export let categories: string[];
	export let categoriesSelector: string | null = null;

	let activeCategory: string = "";
	$: longestCategoryCharCount = maxBy(categories, c => c.length)?.length;

	onMount(() => {
		if (categories == null && categoriesSelector != null) {
			categories = loadFromJsonScriptElement<string[]>(categoriesSelector) ?? [];
			activeCategory = categories[0] ?? "";
		}

		let observer = new IntersectionObserver(
			(entries, _) => {
				let entry = entries.filter((e) => e.isIntersecting)[0];
				if (entry?.isIntersecting) {
					activeCategory = categories.find((c) => slugify(c) === entry.target.id) ?? "";
				}
			},
			{
				// single trigger at top edge of target [threshold: 0], firing 
				// when it hits the middle of the viewport [rootMargin: -50%]
				rootMargin: "-50% 0px",
				threshold: 0,
			}
		);
		for (let element of document.querySelectorAll<HTMLElement>(".gallery-category")) {
			observer.observe(element);
		}
	});
</script>

<div class="dropdown" style={`--maxchars: ${longestCategoryCharCount}`}>
	<div class="dropdown-trigger">
		<button class="button" aria-haspopup="true" aria-controls="gallerycategory-dropdown-menu">
			<span>{activeCategory}</span>
			<SvgIcon icon="arrow-button-down" customClass="is-small" />
		</button>
	</div>
	<div class="dropdown-menu" id="gallerycategory-dropdown-menu" role="menu">
		<div class="dropdown-content">
			{#each categories ?? [] as category}
				<a class="dropdown-item" href={`#${slugify(category)}`}>{category}</a>
			{/each}
		</div>
	</div>
</div>
