<script lang="ts">
	import tippy from "svelte-tippy";

	export let icon: string;
	export let type: string = "";
	export let size: string = "";
	export let customClass: string = "";
	export let customSize: string = "";
	export let isClickable: boolean = false;
	export let isLeft: boolean = false;
	export let isRight: boolean = false;
	export let tooltip: string | null = null;
	export let badgeValue: string | number | null = null;
	export let badgePosition = "is-top-right";

	let newCustomSize = "";
	let newType = "";

	$: svgHref = `#icon:${icon}`;

	$: {
		if (customSize) newCustomSize = customSize;
		else {
			switch (size) {
				case "is-small":
					break;
				case "is-medium":
					break;
				case "is-large":
					break;
				default:
					newCustomSize = "";
			}
		}
	}

	$: {
		if (!type) newType = "";
		let splitType: string[] = [];
		if (typeof type === "string") {
			splitType = type.split("-");
		} else {
			for (let key in type) {
				if (type[key]) {
					splitType = key.split("-");
					break;
				}
			}
		}
		if (splitType.length <= 1) newType = "";
		else newType = `has-text-${splitType[1]}`;
	}
</script>

{#if tooltip}
	<span class="icon is-relative {size} {newType} {customClass} {newCustomSize} {(isLeft && 'is-left') || ''} {(isRight && 'is-right') || ''}" class:is-clickable={isClickable} on:click use:tippy={{content: tooltip}}>
		{#if badgeValue != null}
			<span class="badge {badgePosition}">{badgeValue}</span>
		{/if}
		<svg>
			<use xlink:href={svgHref} />
		</svg>
	</span>
{:else}
	<span class="icon is-relative {size} {newType} {customClass} {newCustomSize} {(isLeft && 'is-left') || ''} {(isRight && 'is-right') || ''}" class:is-clickable={isClickable} on:click>
		{#if badgeValue != null}
			<span class="badge {badgePosition}">{badgeValue}</span>
		{/if}
		<svg>
			<use xlink:href={svgHref} />
		</svg>
	</span>
{/if}
