let navOverlay = document.querySelector(".nav-overlay");
for (let element of document.querySelectorAll(".header-nav-toggle")) {
	element.addEventListener("click", e => {
		navOverlay!.classList.toggle("is-open");
	});
}

for (let element of document.querySelectorAll(".nav-link.level-1")) {
	element.addEventListener("click", e => {
		let target = element.nextElementSibling!;

		if (window.matchMedia("(max-width: 768px)").matches && !target.classList.contains("is-expanded")) {
			e.preventDefault();
		}

		for (let expanded of document.querySelectorAll(".nav-list-wrapper.level-2.is-expanded")) {
			if (expanded === target) {
				continue;
			}
			expanded.classList.remove("is-expanded");
		}
		target.classList.toggle("is-expanded");
	});
}
