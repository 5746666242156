<script lang="ts">
	import tippy from "svelte-tippy";
	import { FluentProvider, Localized } from "@nubolab-ffwd/svelte-fluent";
	import { OpenContactModalEvent } from "./contact-modal.svelte";

	import { bundles, localize } from "@/localization";
	import SvgIcon from "@/components/svg-icon.svelte";

	export let size: "small" | "large";

	function open() {
		document.dispatchEvent(new CustomEvent(OpenContactModalEvent));
	}
</script>

<FluentProvider {bundles}>
	{#if size === "large"}
		<button class="button is-info header-action" use:tippy={{ content: $localize("inquiry-button.tooltip") }} on:click={open}>
			<SvgIcon customClass="icon--inquiry" size="is-large" icon="single-neutral-actions-question" />
			<span><Localized id="inquiry-button" /></span>
		</button>
	{:else if size === "small"}
		<button class="button is-info header-action" use:tippy={{ content: $localize("inquiry-button.tooltip") }} on:click={open}>
			<SvgIcon customClass="icon--inquiry" size="is-medium" icon="single-neutral-actions-question" />
			<span><Localized id="inquiry-button" attr="label-short" /></span>
		</button>
	{/if}
</FluentProvider>
