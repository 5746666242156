import component from "@wagich/svelte-tag";

import FavoriteListButton from "./favorite-list-button.svelte";
new component({
	component: FavoriteListButton,
	tagname: "favorite-list-button",
});

import FavoriteToggle from "./favorite-toggle.svelte";
new component({
	component: FavoriteToggle,
	tagname: "favorite-toggle",
});

if (document.querySelector("favorite-list") != null) {
	import("./favorite-list.svelte").then(m => {
		new component({
			component: m.default,
			tagname: "favorite-list",
		});
	});
}
