import component from "@wagich/svelte-tag";

import InquiryButton from "./inquiry-button.svelte";
new component({
	component: InquiryButton,
	tagname: "inquiry-button",
});

import ContactModal from "./contact-modal.svelte";
new component({
	component: ContactModal,
	tagname: "contact-modal",
});

import ContactForm from "./contact-form.svelte";
new component({
	component: ContactForm,
	tagname: "contact-form",
});
