import Masonry from "masonry-layout";
import component from "@wagich/svelte-tag";
import GalleryCategoryDropdown from "./gallerycategory-dropdown.svelte";

new component({
	component: GalleryCategoryDropdown,
	tagname: "gallerycategory-dropdown",
});

for (let element of document.querySelectorAll<HTMLElement>(".gallery-category__items")) {
	setTimeout(() => {
		new Masonry(element, {
			itemSelector: ".gallery-item",
			gutter: ".gallery-category__gap-sizer",
			horizontalOrder: true,
		});
	}, 0);
}
