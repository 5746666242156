<script lang="ts">
	import tippy from "svelte-tippy";
	import { FluentProvider, Localized } from "@nubolab-ffwd/svelte-fluent";

	import { bundles, localize } from "@/localization";
	import SvgIcon from "@/components/svg-icon.svelte";
	import { store } from "./store";

	export let size: "small" | "large";
	export let targetUrl: string;

	let count: number = 0;
	store.subscribe((state) => {
		count = state.size;
	});
</script>

<FluentProvider {bundles}>
	{#if size === "large"}
		<a class="button is-primary header-action" href={targetUrl} use:tippy={{ content: $localize("favorite-list-button.tooltip") }}>
			<SvgIcon customClass="icon--favorite-list" size="is-large" icon="filter-heart" badgeValue={count} />
			<span><Localized id="favorite-list-button" /></span>
		</a>
	{:else if size === "small"}
		<a class="button is-primary header-action" href={targetUrl} use:tippy={{ content: $localize("favorite-list-button.tooltip") }}>
			<SvgIcon customClass="icon--favorite-list" size="is-medium" icon="filter-heart" badgeValue={count} />
			<span><Localized id="favorite-list-button" attr="label-short"/></span>
		</a>
	{/if}
</FluentProvider>
